import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DashboardCard from "./DashboardCard";
import { Divider } from "@material-ui/core";
import AnalyticsAndBillingLinks from "../../hub/HelperComponents/AnalyticsAndBillingLinks";
import { ReactComponent as CollapseArrow } from "../../../assets/img/icons-new/general/expand/expand-up-gray.svg";
import { ReactComponent as ExpandArrow } from "../../../assets/img/icons-new/general/expand/expand-gray.svg";

const CreditsBreakdownWidget = (props) => {
  const {
    onBeforeDrag,
    isDragging,
    onMouseLeaveDrag,
    isActive,
    index,
    onClickTrash,
    userPermissions,
    creditsSummary,
  } = props;

  const {
    credits,
    saving_credits,
    checking_credits,
    users_assigned_credits,
    total_credit_balance,
  } = creditsSummary || {};

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsExpanded((prevState) => !prevState);
  }, []);

  return (
    <DashboardCard
      headerContent={
        <div className="d-flex">
          <div className="font-weight-normal">Credits</div>
          <AnalyticsAndBillingLinks
            className={"billing-analytic-link ml-2 mr-3"}
            userPermissions={userPermissions}
          />
        </div>
      }
      headerColorType="white"
      onBeforeDrag={onBeforeDrag}
      isDragging={isDragging}
      onMouseLeaveDrag={onMouseLeaveDrag}
      isActive={isActive}
      index={index}
      onClickTrash={onClickTrash}
      containerClassName="align-items-center"
    >
      <div className="credits-summary-container">
        <div className="credits-summary-container-title-total-credits">
          <div>My Available Credits</div>
          <div>{credits}</div>
        </div>
        <div className="credits-summary-container-title-total-credits">
          <div>Total Credit Balance</div>
          <div>{total_credit_balance}</div>
        </div>
        <Divider className="credits-summary-container-divider" />
        <div className="credits-summary-container-title-others">
          <div> Rollover Credits (Savings)</div>
          <div>{saving_credits}</div>
        </div>
        <Divider className="credits-summary-container-divider" />
        <div
          className="credits-summary-container-title-others cursor-pointer"
          onClick={toggleDropdown}
        >
          <div>
            Non-Rollover Credits (Checking)
            {isExpanded ? (
              <CollapseArrow className="collapse-expand-icon ml-1" />
            ) : (
              <ExpandArrow className="collapse-expand-icon ml-1" />
            )}
          </div>
          <div> {checking_credits + users_assigned_credits}</div>
        </div>
        {isExpanded && (
          <>
            <div className="credits-summary-container-section-sub">
              <div>Owners and Admins:</div>
              <div>{checking_credits}</div>
            </div>
            <div className="credits-summary-container-section-sub">
              <div>Users:</div>
              <div>{users_assigned_credits}</div>
            </div>
          </>
        )}
      </div>
    </DashboardCard>
  );
};

CreditsBreakdownWidget.propTypes = {
  onBeforeDrag: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onMouseLeaveDrag: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func,
};
export default CreditsBreakdownWidget;
